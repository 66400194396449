@import url("https://fonts.googleapis.com/css2?family=Advent+Pro:wght@200&family=Architects+Daughter&family=Bad+Script&family=Dancing+Script:wght@600&family=Gloria+Hallelujah&family=IBM+Plex+Serif:wght@700&family=Josefin+Sans:wght@300;400;700&family=Jura:wght@500;700&family=Meow+Script&family=Open+Sans:wght@300&family=Pacifico&family=Permanent+Marker&family=Pixelify+Sans&family=Poppins:wght@900&family=Quicksand&family=Rajdhani:wght@500&family=Rubik+Mono+One&family=Space+Mono:wght@700&family=Work+Sans&display=swap");
* {
  margin: 0px;
  padding: 0px;
}

body {
  font-family: "Exo", sans-serif;
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: hidden;
  background-image: radial-gradient(
    circle farthest-side at var(--x, 100px) var(--y, 100px),
    #1250aa 0%,
    transparent 100%
  );
  background-size: cover;
  background-attachment: fixed;
}

main {
  overflow-y: auto;
  height: 100vh;
}

.hero {
  width: 100%;
  height: 100vh;
  background-image: radial-gradient(
    circle farthest-side at var(--x, 100px) var(--y, 100px),
    #1250aa 0%,
    transparent 100%
  );
  background-size: cover;
  background-attachment: fixed;
}

.component-wrapper {
  max-width: 1100px;
  margin: 0 auto;
  padding: 20px;
}

.typewriter {
  color: rgb(74 222 128);
  font-family: monospace;
  overflow: hidden;
  border-right: 0.15em solid rgb(74 222 128);
  white-space: nowrap;
  letter-spacing: 0.15em;
  animation: typing 4.5s steps(30, end), blink-caret 0.5s step-end infinite;
}

@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: rgb(74 222 128);
  }
}

p,
a,
button {
  font-family: "Jura", sans-serif;
  @media (max-width: 426px) {
    font-size: 12px;
  }
}
button {
  @media (max-width: 426px) {
    font-size: 12px;
  }
}

h1 {
  font-family: "Space Mono", monospace;
}

h3 {
  font-family: "Pixelify Sans", sans-serif;
}
h5 {
  font-family: "Pixelify Sans", sans-serif;
}
.contact-title {
  width: fit-content;
  margin: 0 auto;
}

@media screen and (min-width: 375px) and (max-width: 426px) {
  .landing-page-container {
    margin-left: 50px;
  }

  .project-header {
    display: grid;
  }

  .project-image {
    width: 70%;
  }

  .project-description {
    display: none;
  }

  .skills-icons {
    display: grid;
    grid-template-columns: auto auto auto auto;
  }
  .skill-title {
    display: none;
  }
  .map {
    width: 220px;
    height: 40px;
    margin: 0 auto;
  }
  .addressAnsEmail-contact {
    display: none;
  }
}

@media screen and (min-width: 300px) and (max-width: 374px) {
  #about {
    margin-top: 50px;
    margin: 0 auto;
    width: 100%;
  }

  #skills {
    margin-top: 50px;
    width: 100%;
    margin: 0 auto;
  }
  .skills-icons {
    display: grid;
    grid-template-columns: auto auto;
  }
  .skill-title {
    display: none;
  }

  #contact {
    margin-top: 50px;
    width: 100%;
  }
  .map {
    width: 220px;
    height: 40px;
    margin: 0 auto;
  }
  .addressAnsEmail-contact {
    display: none;
  }
}
